import type { InputBaseComponentProps } from '@mui/material'
import { forwardRef } from 'react'
import { NumberFormatBase } from 'react-number-format'

export const SocialSecurityNumberFormatter = forwardRef<
  unknown,
  {
    onChange: (event: { target: { value: string | null } }) => void
  }
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props

  const isAllowed = (value: string) => {
    if (value.length > 15) return false
    return /^(|[0-9]{0,6}[AB0-9][0-9]*)$/i.test(value)
  }

  const formatSSN = (value: string) => {
    let formattedValue = ''
    for (let i = 0; i < value.length; i++) {
      if ([1, 3, 5, 7, 10, 13].includes(i)) {
        formattedValue += ' '
      }
      formattedValue += value[i]
    }

    return formattedValue
  }

  return (
    <NumberFormatBase
      {...other}
      format={formatSSN}
      removeFormatting={(value) => value.replace(/\s+/gi, '')}
      isAllowed={(values) => isAllowed(values.value)}
      getCaretBoundary={(value) =>
        Array(value.length + 1)
          .fill(0)
          .map(() => true)
      }
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value === undefined ? null : values.value,
          },
        })
      }}
    />
  )
}) as unknown as React.ElementType<InputBaseComponentProps>
