import { css } from '@emotion/react'
import type { MutaBeneficiaries, MutaBeneficiary, MutaBeneficiaryType } from '@orus.eu/dimensions'
import { memo } from 'react'
import { colors, spacing, useLanguage } from '../../../../index.js'
import { Avatar, Chip, RowButtonV2, RowContainerV2 } from '../../../index.js'

import { PARIS, calendarDateToString } from '@orus.eu/calendar-date'
import child from '../emoji/child.png'
import kiss from '../emoji/kiss.png'
import personWhiteHair from '../emoji/person-white-hair.png'

type BeneficiariesListProps = {
  beneficiaries: MutaBeneficiaries
  isCustomerApp?: boolean
  onBeneficiaryClick?: (beneficiary: MutaBeneficiary, index: number) => void
}

export const BeneficiariesList = memo<BeneficiariesListProps>(function BeneficiariesList(props) {
  const language = useLanguage()
  const { beneficiaries, isCustomerApp, onBeneficiaryClick } = props
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[70]};
      `}
    >
      {beneficiaries.length > 0 ? (
        <RowContainerV2
          css={css`
            border-radius: ${isCustomerApp ? '0px' : undefined};
          `}
        >
          {beneficiaries.map((beneficiary, index) => {
            const isComplete = isMemberCompleted(beneficiary)
            return (
              <>
                <RowButtonV2
                  key={beneficiary.firstName + '-' + beneficiary.lastName}
                  avatarLeft={<Avatar src={avatarPerBeneficiaryType[beneficiary.type]} size="50" />}
                  avatarRight={
                    isCustomerApp ? undefined : (
                      <Chip
                        size="small"
                        backgroundColor={colors[isComplete ? 'green' : 'orange'][200]}
                        textColor={colors[isComplete ? 'green' : 'orange'][900]}
                      >
                        {isComplete ? 'Terminé' : 'A compléter'}
                      </Chip>
                    )
                  }
                  primaryText={beneficiary.firstName}
                  secondaryText={
                    beneficiary.birthDate
                      ? calendarDateToString(beneficiary.birthDate, PARIS, 'DATE_SHORT', language) +
                        ' - ' +
                        namingPerBeneficiaryType[beneficiary.type]
                      : namingPerBeneficiaryType[beneficiary.type]
                  }
                  onClick={() => onBeneficiaryClick?.(beneficiary, index)}
                />
              </>
            )
          })}
        </RowContainerV2>
      ) : (
        <></>
      )}
    </div>
  )
})

function isMemberCompleted(beneficiary: MutaBeneficiary): boolean {
  const isChildRankOk = beneficiary.type !== 'CHILD' || beneficiary.socialSecurityRank !== undefined

  if (
    beneficiary.lastName &&
    beneficiary.firstName &&
    beneficiary.birthCity &&
    beneficiary.birthCountry &&
    beneficiary.socialSecurityNumber &&
    beneficiary.socialSecurityOrganismNumber &&
    isChildRankOk
  )
    return true

  return false
}

const avatarPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: kiss,
  CHILD: child,
  OTHER: personWhiteHair,
}

const namingPerBeneficiaryType: Record<MutaBeneficiaryType, string> = {
  PARTNER: 'Votre conjoint',
  CHILD: 'Votre enfant',
  OTHER: 'Une personne à charge',
}
