import { css } from '@emotion/react'
import { FormControl, FormControlLabel, RadioGroup } from '@mui/material'

import { memo, type ChangeEvent } from 'react'
import { Avatar, Text } from '../../atoms/index.js'

import styled from '@emotion/styled'
import { spacing } from '../../../foundation/spacing-tokens.js'
import { Radio } from '../../atoms/radio.js'
import { SummaryTable } from '../../atoms/summary-table.js'
import { Tooltip } from '../../tooltip/tooltip.js'

export type RadioItem = {
  title: string
  tooltip?: string
}

export type RowContainerWithRadioProps = {
  items: RadioItem[]
  value?: string
  handleChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void
}

export const RowContainerWithRadio = memo<RowContainerWithRadioProps>(function RowContainerWithRadio(props) {
  const { items, handleChange, value } = props
  return (
    <FormControl
      css={css`
        width: 100%;
        margin-top: 0;
      `}
    >
      <RadioGroup defaultValue={items[0]?.title} onChange={handleChange} value={value}>
        <SummaryTable
          css={css`
            padding: 0;
            margin: 0;
            background-color: white;
          `}
        >
          {items.map((item) => (
            <div
              key={item.title}
              css={css`
                display: flex;
                flex-direction: column;
                padding: ${spacing[50]};
              `}
            >
              <FormControlLabelContainer>
                <FormControlLabel
                  control={
                    <div
                      css={css`
                        padding: ${spacing[50]};
                      `}
                    >
                      <Radio value={item.title} size="small" disabled={items.length === 1} />
                    </div>
                  }
                  label={<Text variant="body2">{item.title}</Text>}
                  disabled={items.length === 1}
                  checked={items.length === 1}
                />

                {item.tooltip ? (
                  <Tooltip title={item.tooltip}>
                    <Avatar variant="full" icon="circle-info-regular" size="30" />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </FormControlLabelContainer>
            </div>
          ))}
        </SummaryTable>
      </RadioGroup>
    </FormControl>
  )
})

const FormControlLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20px;

  .MuiFormControlLabel-root {
    width: 100%;
  }
`
