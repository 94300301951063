import { failure, success } from '@orus.eu/result'
import type { ValidatedTypeMapper } from '../../../index.js'

const DIGIT_REGEX = /^\d$/

export const SOCIAL_SECURITY_RANK_MAPPER: ValidatedTypeMapper<string> = {
  inputType: 'text',
  format: (value) => value.trim(),
  formatPlaceholder: (value) => value.trim(),
  parse: (text) => {
    return DIGIT_REGEX.test(text.trim()) ? success(text) : failure('Le rang doit être un chiffre')
  },
}
