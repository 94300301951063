import type { InputBaseComponentProps } from '@mui/material'
import { forwardRef } from 'react'
import { PatternFormat } from 'react-number-format'

type CustomProps<VALUE> = {
  onChange: (event: { target: { value: VALUE | null } }) => void
}

export const SocialSecurityOrganismNumberFormatter = forwardRef<unknown, CustomProps<string>>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <PatternFormat
        format={'## ### ####'}
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value === undefined ? null : values.value,
            },
          })
        }}
        valueIsNumericString={true}
      />
    )
  },
) as unknown as React.ElementType<InputBaseComponentProps>
